import { WebBodyText18, WebButton16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Radio } from '@/components/common/Radio/Radio';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { TradeSide, intervals } from '../schema';
import { FrequencyDisplayStringMap } from '@fintronners/react-api';
import { InlineDatepicker } from '~components/common/Datepicker/InlineDatepicker';
import { TradeCurrency } from './TradeCurrency';
import ACHAccountWidget from '~components/features/bankLink/ACHAccountWidget';
import useCurrentAccountType from '@/hooks/utils/useCurrentAccountType';
import useUserAccount from '@/hooks/api/useUserAccount';
import { AccountType } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

const SUGGESTED_AMOUNT_OPTIONS = [25, 50, 100];

type TradeRepeatInvestmentFormProps = {
  setIsAccountValid: (isValid: boolean) => void;
};

export const TradeRepeatInvestmentForm = ({
  setIsAccountValid,
}: TradeRepeatInvestmentFormProps) => {
  const { control } = useFormContext();
  const amount = useWatch({ control, name: 'amount' });
  const accountType = useCurrentAccountType();
  const { loading: accountLoading } = useUserAccount(accountType);

  return (
    <div className="flex flex-col gap-10">
      <div>
        <TradeCurrency side={TradeSide.BUY} />
        <ul className="flex gap-4">
          {SUGGESTED_AMOUNT_OPTIONS.map((suggestedOption) => (
            <li key={suggestedOption} className="w-full">
              <Controller
                control={control}
                name="amount"
                render={({ field: { value, onChange } }) => (
                  <>
                    <Radio.Input
                      id={suggestedOption.toString()}
                      value={value}
                      onChange={() => onChange(Number(suggestedOption))}
                      checked={suggestedOption === value || suggestedOption === Number(amount)}
                    />
                    <Radio.Label variant="secondary" htmlFor={suggestedOption.toString()}>
                      <WebButton16.Bold>
                        {thousandFormatNumbroCurrency(suggestedOption, {
                          trimMantissa: true,
                        })}
                      </WebButton16.Bold>
                    </Radio.Label>
                  </>
                )}
              />
            </li>
          ))}
        </ul>
      </div>
      <div>
        <ul className="flex gap-4">
          {intervals.map((_interval) => (
            <li key={_interval} className="w-full">
              <Controller
                control={control}
                name="interval"
                render={({ field: { value, onChange } }) => (
                  <>
                    <Radio.Input
                      id={_interval}
                      value={value}
                      onChange={() => onChange(_interval)}
                      checked={_interval === value}
                    />
                    <Radio.Label variant="secondary" htmlFor={_interval}>
                      <WebButton16.Bold>{FrequencyDisplayStringMap[_interval]}</WebButton16.Bold>
                    </Radio.Label>
                  </>
                )}
              />
            </li>
          ))}
        </ul>
        <Controller
          control={control}
          name="selectedStartDate"
          render={({ field: { value, onChange } }) => (
            <InlineDatepicker
              onChange={(date) => onChange(date?.toISOString())}
              defaultValue={new Date(value ?? '')}
            />
          )}
        />
      </div>
      <div>
        <WebBodyText18.Bold className="text-darkestBlue">Funding source</WebBodyText18.Bold>
        <ACHAccountWidget
          accountType={AccountType.BROKERAGE}
          loading={accountLoading}
          setIsAccountValid={setIsAccountValid}
        />
      </div>
    </div>
  );
};
